import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, where } from "firebase/firestore";

let app;
let db;

export function initializeFirebase(env) {
    const firebaseConfig = {
        apiKey: env.FIREBASE_API_KEY,
        authDomain: env.FIREBASE_AUTH_DOMAIN,
        projectId: env.FIREBASE_PROJECT_ID,
        storageBucket: env.FIREBASE_STORAGE_BUCKET,
        messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
        appId: env.FIREBASE_APP_ID,
        measurementId: env.FIREBASE_MEASUREMENT_ID
    };

    app = initializeApp(firebaseConfig);
    db = getFirestore(app);
}


// Function to add a new booking
export const addBooking = async (bookingData) => {
    try {
        const bookingsCollection = collection(db, "bookings");
        const docRef = await addDoc(bookingsCollection, {
        ...bookingData,
        createdAt: new Date()
        });
        console.log("Booking added with ID: ", docRef.id);
        return docRef.id;
    } catch (error) {
        console.error("Error adding booking: ", error);
        throw error;
    }
};
  
// Function to fetch all bookings
export const fetchBookings = async () => {
    try {
        const bookingsCollection = collection(db, "bookings");
        const bookingsSnapshot = await getDocs(bookingsCollection);
        return bookingsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
        }));
    } catch (error) {
        console.error("Error fetching bookings: ", error);
        throw error;
    }
};

// Function to fetch bookings for a specific date
export const fetchBookingsForDate = async (date) => {
    try {
        const bookingsCollection = collection(db, "bookings");
        const q = query(bookingsCollection, where("date", "==", date));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
        }));
    } catch (error) {
        console.error("Error fetching bookings for date: ", error);
        throw error;
    }
};

// Function to fetch all shifts
export const fetchShifts = async () => {
    try {
        const shiftsCollection = collection(db, "shifts");
        const shiftSnapshot = await getDocs(shiftsCollection);
        return shiftSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
        }));
    } catch (error) {
        console.error("Error fetching bookings: ", error);
        throw error;
    }
};

export const fetchShiftTimes = (() => {
    let shiftTimes = [];
  
    return async () => {
      if (shiftTimes.length === 0) {
        try {
          const shifts = await fetchShifts();
          shiftTimes = shifts.map(shift => shift.time);
        } catch (error) {
          console.error("Error fetching shift times: ", error);
          throw error;
        }
      }
      return shiftTimes;
    };
  })();
  


  