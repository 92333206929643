import * as React from 'react';
import BookingCard from './BookingDayCard';


export default function BookingGrid({ bookings }) {
  return (
    <div className="h-auto pb-10 w-full">
      <div className="p-6 flex-1 overflow-auto">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
          {bookings &&
            bookings.map(booking => 
              <BookingCard 
                key={booking.id}
                name={booking.name}
                email={booking.email}
                time={booking.time}
                phone={booking.phone}
                guests={booking.guests}
               />
            )}
        </div>
      </div>
    </div>
  );
}