import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DrawerAppBar from './components/Navbar';
import { createTheme } from '@mui/material';
import Cover from './components/Cover';
import AboutUs from './components/AboutUs';
import Food from './components/Food';
import Drinks from './components/Drinks';
import Contact from './components/Contact';
import Booking from './components/Booking';
import Menu from './components/Menu';
import Menu2MainNavBar from './components/Menu2MainNavBar';
import Bookings2MainNavBar from './components/Bookings2MainNavBar';
import BookingDashboard from './components/BookingDashboard';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#133C31',
    },
    secondary: {
      main: '#E2D0B8',
    },
  },
});

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <DrawerAppBar theme={theme}/>
                <Cover covertype={{text:"RESERVA TU MESA", event:false }}/>
                <div id="aboutus">
                  <AboutUs />
                </div>
                <div id="food">
                  <Food />
                </div>
                <div>
                  <Drinks />
                </div>
                <div id="events">
                  <div className="bg-main-green py-1 lg:py-4"/>
                  <Cover covertype={{text:"CELEBRA TU EVENTO CON NOSOTROS", event:true }}/>
                </div>
                <div id="contact">
                  <Contact />
                </div>
                <Footer/>
              </>
            }
          />
          <Route path="/booking"
            element={
              <>
                <Bookings2MainNavBar theme={theme} />
                <Booking />
              </>
            }
          />
          <Route path="/menu"
            element={
              <>
                <Menu2MainNavBar theme={theme} />
                <Menu theme={theme} />
              </>
            }
          />
          <Route path='/dashboard'
            element={
              <>
                <Bookings2MainNavBar theme={theme} />
                <BookingDashboard />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;