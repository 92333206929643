import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from './../images/logo.svg'

function Bookings2MainNavBar(props) {
    return(   
        <AppBar component="nav" sx={{ backgroundColor: props.theme.palette.primary.main }}>
            <Toolbar>
                <Typography
                    component="div"
                    sx={{ flexGrow: 1, display: { sm: 'block' } }}
                >
                    <a href='/'>
                    <img src={Logo} alt="Logo Osteria" class="w-20" />
                    </a>
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Bookings2MainNavBar;