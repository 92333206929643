import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import BookingsDayGrid from "./BookingsGrid";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { fetchBookingsForDate } from '../services/BookingService';


function BookingDashboard() {
    const [daySelected, setDaySelected] = useState(dayjs());
    const [bookings, setBookings] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {
        fetchBookingsForDate(dayjs().format("YYYY-MM-DD")).then((data) => {
            setBookings(data);
        });
    }, []);

    const changeCalendarSelection = (newValue) => {
        setDaySelected(newValue);
        fetchBookingsForDate(dayjs(newValue).format("YYYY-MM-DD")).then((data) => {
            setBookings(data);
        });
        setShowCalendar(false);
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    return (
        <>
            <div className="flex flex-col justify-between mt-20 bg-[#f3f0e2] ">
                <div className="flex flex-col md:flex-row md:items-start justify-between items-center px-10 pt-5 pb-10">
                    <h1 className="font-bold text-xl text-center md:text-3xl mb-12 mx-10">RESERVAS OSTERIA PASTAI</h1>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {showCalendar ? (
                            <div className="pt-4 bg-main-gold border-2 border-main-green mx-auto max-w-xs">
                                <DateCalendar value={daySelected} onChange={changeCalendarSelection} />
                            </div>
                        ) : (
                            <div className="px-12 py-4 bg-main-gold shadow-md rounded-md max-w-md 
                                            cursor-pointer mx-auto border-2 border-main-green 
                                            transition-transform transform hover:scale-105 hover:shadow-lg"
                                onClick={toggleCalendar}>
                                <h2 className="text-lg font-bold text-center">{daySelected.format("DD-MM-YYYY")}</h2>
                            </div>
                        )}
                    </LocalizationProvider>
                </div>
                {bookings &&
                    <div className="flex flex-row px-4 py-5 border-t-2 border-t-main-green">
                        <BookingsDayGrid bookings={bookings} />
                    </div>
                }
            </div>
        </>
    );
}


export default BookingDashboard;