import * as React from 'react';

function BookingCard({ id, name, email, guests, phone, time, confirmed }) {
    return (
        <div className="border-2 py-6 px-6 rounded-md border-main-green shadow-lg bg-main-gold transition-transform transform hover:scale-105">
            <div className="flex flex-col items-center space-y-4">
                <div className="flex flex-col items-center text-center">
                    <h2 className="text-lg font-bold">{time}</h2>
                </div>
                <div className="flex flex-col items-center text-center">
                    <h2 className="text-lg font-semibold">{name}</h2>
                </div>
                <div className="flex flex-col items-center text-center">
                    <h3 className="text-md">{guests} Personas</h3>
                </div>
                <div className="flex flex-col items-center text-center w-full">
                    <h3 className="text-md">{email}</h3>
                    <h3 className="text-md">{phone}</h3>
                </div>
            </div>
        </div>
    );
}

export default BookingCard;